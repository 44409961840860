.notfound{
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.notfound .not-found-emoji{
    font-size: 10rem;
}

.notfound .not-found-desc{
    font-size: 2rem;
}

