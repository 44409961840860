@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

:root{
    --first-color:orange;
   --second-color:#ed9e50
}


.service{
    padding: 2rem 10%;
}

.service .service-title, .service .service-desc{
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.service .service-title{
    font-size: 2rem;
    margin-bottom: 1rem;
}

.service .service-row{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0 20%;
    font-family: 'Poppins', sans-serif;
}

.service .service-row .card-service{
    height: 21rem;
    width: 23rem;
    box-shadow: 0 0 8px rgba(0, 0, 0, .75);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service .service-row .card-service .icon-card{
    font-size: 6.4rem;
    color: var(--first-color);
}
.service .service-row .card-service h3{
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}
.service .service-row .card-service p{
    font-size: .8rem;
    text-align: center;
    margin-bottom: 1rem;
}

.service .service-row .card-service a{
    color: var(--first-color);
}

.service .service-row .card-service a:hover{
    color: #041824;
}



/* RESPONSIVE BREAKPOINT */
@media(max-width: 1668px) {
    .service .service-row{
        padding: 0 12%;
    }
}

@media(max-width: 1340px) {
    .service .service-row{
        padding: 0 5%;
    }
}

@media(max-width: 450px) {
    .service .service-row .card-service{
        width: 100%;
        /* height: 30rem; */
    }

    .serivce .service-row .card-service .icon-card{
        font-size: 4rem;
    }

    .service .service-row .card-service h3{
        font-size: .9rem;
    }

    .service .service-row .card-service p{
        font-size: .8rem;
    }

    .service .service-row .card-service a{
        font-size: .8rem;
    }
}