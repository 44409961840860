@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

:root{
    --first-color:orange;
   --second-color:#ed9e50
}

/* PARTNERSHIP HEADING */
.partnership{
    padding: 8rem 2% 25rem;
    font-family: 'Poppins', sans-serif;
}

.partnership .title-partnership{
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 0.15rem;
}

.partnership .desc-partnership{
    text-align: center;
    margin-bottom: 2rem;
}
/* PARTNERSHIP HEADING */

/* PARTNERSHIP CARD */
.partnership .row-partnership{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.partnership .row-partnership1 .card-partnership1{
    height: 60%;
    width: 50%;
    border: .15rem solid var(--first-color);
    border-radius: .3rem;
    overflow: hidden;
    box-shadow: 0 0 8px rgba(0, 0, 0, .50);
}

/* .partnership .row-partnership .card-partnership .thumb-image-partnership{
    height: 125rem;
    width: 100%;
} */

.partnership .row-partnership .card-partnership .thumb-image-partnership img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.partnership .row-partnership .card-partnership .desc-card-partnership{
    padding: .5rem;
}

.partnership .row-partnership .card-partnership .desc-card-partnership p{
    font-size: .7rem;
}

.partnership .row-partnership .card-partnership .desc-card-partnership p span{
    color: red;
}

.partnership .row-partnership .card-partnership .desc-card-partnership a{
    margin-top: 2rem;
    display: flex;
    justify-content: right;
    text-decoration: none;
    color: var(--first-color);
}
/* PARTNERSHIP CARD */

@media(max-width: 657px) {
    .partnership .row-partnership1 .card-partnership1{
        height: 100%;
        width: 100%;
        border: .15rem solid var(--first-color);
        border-radius: .3rem;
        overflow: hidden;
        box-shadow: 0 0 8px rgba(0, 0, 0, .50);
    }
    .partnership .title-partnership{
        text-align: center;
        font-size: 0.90rem;
        margin-bottom: 0.15rem;
    }
}