@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

:root{
    --first-color:orange;
   --second-color:#ed9e50
}

.whyus{
    padding: 2rem 15%;
    font-family: 'Poppins', sans-serif;
}

.whyus .whyus-title{
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--first-color);
    font-weight: bolder;
}


.whyus .row-whyus .whyus-card{
    margin-bottom: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, .75);
    border-radius: 2rem;
    height: 20rem;
}

.whyus .row-whyus .col-2{
    flex-direction: row-reverse;
}

.whyus .row-whyus .whyus-card .thumb-whyus-image{
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
}

.whyus .row-whyus .whyus-card .thumb-whyus-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.whyus .row-whyus .whyus-card .desc-card{
    width: 100%;
}

.whyus .row-whyus .whyus-card .desc-card h3{
    color: black;
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
}

.desc-card1{
    font-size: 0.90rem;
}




/* RESPONSIVE BREAKPOINT */
@media(max-width: 1142px) {
    .whyus .row-whyus .whyus-card .thumb-whyus-image{
        width: 45%;
    }
    .whyus .row-whyus .whyus-card .desc-card{
        width: 100%;
    }
    .whyus .row-whyus .whyus-card .desc-card h3{
        font-size: 1.09rem;
    }

    .whyus .row-whyus .whyus-card .desc-card p{
        font-size: .85rem;
    }
}

@media(max-width: 868px) {
    .whyus .row-whyus .whyus-card{
        flex-direction: column;
        justify-content: center;
        gap: .5rem;
        height: 40rem;
    }

    .whyus .row-whyus .whyus-card .thumb-whyus-image{
        width: 80%;
        height: 50%;
    }
    .whyus .row-whyus .whyus-card {
        height: 100%;
        width: 115%;
        margin-left: -8%;
        text-align: center !important;
    }

    .whyus .row-whyus .whyus-card .thumb-whyus-image img{
        width: 100%;
        height: 100%;
    }
}

@media(max-width: 526px) {
    .whyus .row-whyus .whyus-card{
        padding: .5rem 1rem;

    }
    .whyus .row-whyus .whyus-card .desc-card h3{
        font-size: .8rem;
    }

    .whyus .row-whyus .whyus-card .desc-card p{
        font-size: .70rem;
    }
}