@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

:root{
    --first-color:orange;
   --second-color:#ed9e50
}

/* PARTNERSHIP HEADING */
.partnership{
    padding: 8rem 2% 25rem;
    font-family: 'Poppins', sans-serif;
}

.partnership .title-partnership{
    text-align: center;
    font-size: 2rem;
    margin-bottom: .5rem;
}

.partnership .desc-partnership{
    text-align: center;
    margin-bottom: 2rem;
}
/* PARTNERSHIP HEADING */

/* PARTNERSHIP CARD */
.partnership .row-partnership{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.partnership .row-partnership .card-partnership{
    height: 28rem;
    width: 24rem;
    border: .15rem solid var(--first-color);
    border-radius: .3rem;
    overflow: hidden;
    box-shadow: 0 0 8px rgba(0, 0, 0, .50);
}

.partnership .row-partnership .card-partnership .thumb-image-partnership{
    height: 14rem;
    width: 100%;
}

.partnership .row-partnership .card-partnership .thumb-image-partnership img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.partnership .row-partnership .card-partnership .desc-card-partnership{
    padding: .5rem;
}

.partnership .row-partnership .card-partnership .desc-card-partnership p{
    font-size: .7rem;
}

.partnership .row-partnership .card-partnership .desc-card-partnership p span{
    color: red;
}

.partnership .row-partnership .card-partnership .desc-card-partnership a{
    margin-top: 2rem;
    display: flex;
    justify-content: right;
    text-decoration: none;
    color: var(--first-color);
}
/* PARTNERSHIP CARD */









/* Button Css */
button {
    --green: red;
    font-size: 15px;
    padding: 0.6em 0.8em;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    position: relative;
    font-family: inherit;
    border-radius: 0.6em;
    overflow: hidden;
    transition: all 0.3s;
    line-height: 1.4em;
    border: 2px solid var(--green);
    background: linear-gradient(to right, rgba(137, 58, 18, 0.1) 1%, transparent 40%,transparent 60% , rgba(250, 48, 17, 0.1) 100%);
    color: orange;
    box-shadow: inset 0 0 10px rgba(164, 115, 24, 0.4), 0 0 9px 3px rgba(255, 125, 19, 0.1);
  }
  
  button:hover {
    color: #e55e1a;
    box-shadow: inset 0 0 10px rgba(217, 109, 14, 0.6), 0 0 9px 3px rgba(175, 87, 9, 0.2);
  }
  
  button:before {
    content: "";
    position: absolute;
    left: -4em;
    width: 3em;
    height: 100%;
    top: 0;
    transition: transform .4s ease-in-out;
    background: linear-gradient(to right, transparent 1%, rgba(255, 85, 7, 0.1) 40%,rgba(225, 67, 5, 0.1) 60% , transparent 100%);
  }
  
  button:hover:before {
    transform: translateX(15em);
  }